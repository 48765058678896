import { mapState } from 'vuex'
import { dateTimeFormatDate, formatStatusDonation, formatValueCurrency } from '@/helpers'

export default {
  name: 'TDonationsTable',
  props: {
    orderBy: {
      type: Function
    }
  },
  data () {
    return {
      thead: [
        { title: 'Status', classes: '', key: 'status', order: null },
        { title: 'Data/Hora de criação', classes: '', key: 'createdAt', order: null },
        { title: 'Doador', classes: '', key: 'customerName', order: null },
        { title: 'Metodo', classes: '', key: 'paymentMethod', order: null },
        { title: '$ Valor', classes: 'right aligned collapsing', key: 'amount', order: null }
      ]
    }
  },
  computed: {
    ...mapState({
      isLoading: ({ donations }) => donations.isLoading,
      donations: ({ donations }) => donations.donationsData,
    }),
  },
  methods: {
    itemId: item => item.id,
    formatedData (data) {
      return {
        status: formatStatusDonation(data.status, data.statusLabel),
        date_time: dateTimeFormatDate(data.createdAt),
        donor: data.customer.fullName,
        method: data.paymentMethodLabel,
        cash: formatValueCurrency(data.amount)
      }
    },

    orderTable (field, order) {
      const header = this.thead.find( head => head.key === field )
      if (!order || order === 'asc') {
        order = 'desc'
      } else {
        order = 'asc'
      }
      header.order = order

      this.thead.forEach(head => {
        if (head.key !== field) head.order = null
      })

      this.$emit('orderBy', { field, order })
    }
  }
}
